<template>
    <div class="record">
        <h5header :name="$t('quotes.desc30')" :right="false" />
        <div class="record-con">
            <van-list v-model:loading="loading" :loading-text="$t('quotes.desc20')" :finished="finished" @load="onLoad">
                <van-cell v-for="item in list" :key="item.id">
                    <div class="record-list flex" @click="handlerInfo(item)">

                        <div class="list-info">
                            <div class="info-name">
                                <div v-if="item.sellType == 1">{{ $t('quotes.desc24') }}- {{ item.payCoinName }}</div>
                                <div style="color:#C9FA5B" v-else>{{ $t('quotes.desc35') }} - {{ item.payCoinName }}</div>
                            </div>
                            <div class="info-list flex">
                                <div class="info">
                                    <div>{{ $t('transfer.desc6') }}</div>
                                    <p>{{ common.cutXiaoNum1(item.payAmount) }}</p>
                                </div>
                                <div class="info">
                                    <div>{{ $t('transfer.desc14') }}</div>
                                    <p>{{ $t('market.desc77') }}</p>
                                </div>
                                <div class="info">
                                    <div>{{ $t('transfer.desc12') }}</div>
                                    <p>{{item.datetime }}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </van-cell>
            </van-list>
            <div class="no-data" v-if="showNull">
                <img src="../../public/images/no-data.png" alt="">
                <span>{{ $t('record.desc13') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
export default {
    components: {
        h5header
    },
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            page: 1,
            showNull: false
        }
    },
    methods: {
        onLoad() {
            setTimeout(() => {
                this.$post(this.URL.quotes.record, {
                    page: this.page,
                    pageSize: 10,
                }).then(res => {
                    if (res.code == 0) {
                        if (this.page == 1) {
                            this.list = res.data.list
                        } else {
                            this.list = this.list.concat(res.data.list)
                        }
                        this.list.forEach(e => {
                            let time= this.common.dateBJtoLocal(e.date),time1=time.getTime(),time2=4*60*60*1000;
                            e.datetime = this.common.formatDate3(time1+time2);
                        })
                        this.page++;
                        this.loading = false;
                        if (this.list.length == 0) {
                            this.showNull = true;
                        }
                        if (this.list.length >= parseFloat(res.data.page.count)) {
                            this.finished = true;
                        }

                    }
                })
            }, 500);
        },
        handlerInfo(info) {
            localStorage.setItem('recordinfo', JSON.stringify(info))
            this.$router.push('/quotes/recordinfo')
        }
    }
}
</script>

<style lang="less" scoped>
.record {
    padding: 20px 16px 0;
    background: url('../../public/images/new/h5bg.png') center no-repeat;
    background-size: 100% 100%;
}

.no-data {
    width: 100%;
    padding: 130px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #A1A1A1;
    }
}

.record-con {
    padding: 56px 0;

    ::v-deep .van-list {
        border-radius: 8px;
        background: #181818;
        padding: 0 14px;

        .van-cell {
            padding: 15px 0;
            background: none;

            &::after {
                border-bottom: 1px solid rgba(255, 255, 255, 0.06);
            }

            .record-list {
                .logo {
                    flex: 0 0 36px;
                    width: 36px;
                    height: 36px;
                    margin-right: 8px;
                }

                .left {
                    flex: 0 0 4px;
                    width: 4px;
                    height: 6px;
                    margin: 15px 0 0 9px;
                }

                .list-info {
                    flex: 1;

                    .info-name {
                        font-size: 16px;
                        color: #EB4343;
                        line-height: 16px;
                    }

                    .info-list {
                        margin-top: 16px;

                        .info {
                            flex: 0 0 30%;

                            &:nth-child(2) {
                                text-align: center;
                            }

                            &:last-child {
                                flex: 0 0 40%;
                                text-align: right;
                            }

                            font-size: 12px;
                            color: #BBBBBB;
                            line-height: 12px;

                            p {
                                margin-top: 8px;
                                font-size: 14px;
                                color: #FFFFFF;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:767px) {
    .record{
        padding: 0 16px;
        min-height: 100vh;
    }
}
</style>